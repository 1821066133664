import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"

const ZonaVoltioPage = () => {
  const { strapiVoltioZone } = useStaticQuery(graphql`
    query {
      strapiVoltioZone {
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            id
            url
            localFile {
              url
            }
          }
        }
        blocks {
          __typename
          ... on STRAPI__COMPONENT_ZONA_VOLTIO_VOLTIO_MAP_ZONE {
            paths {
              strapi_json_value {
                lat
                lng
              }
            }
            gmapsConfig {
              mapId
              zoom
              center {
                lat
                lng
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_MAIN_IMAGE_CAROUSEL {
            slides {
              id
              label
              description
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_HEADER {
            headerTitle: title
            cols
            cover {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_SECTION_TITLE {
            highlightClass
            sectionTitle
            className
            highlight
            level
            note
            noteButtonText
            noteButtonURL
            width
          }
          # ...BlockHeroSection
          # ...BlockImageSupport
          # ...BlockResponsiveMedia
          # ...BaseNestedIconCard
          ...BaseParkingList
          ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
            richTextBody: body {
              data {
                id
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  `)

  const { defaultSeo, blocks } = strapiVoltioZone

  return (
    <Layout>
      <Seo seo={{ ...defaultSeo, url: "zona-voltio" }} />
      <BaseBlocksRenderer blocks={blocks} />
    </Layout>
  )
}

export default ZonaVoltioPage
